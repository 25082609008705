import axios from "@/utils/axios";
import { jingyu_clientkey } from "@/utils/config";

// 邮箱登录
export function mailLogin(params) {
  return axios.post("/api/v3/account/web/login_hybrid_center", params);
}

// 验证手机号注册
export async function checkPhoneExist(phone) {
  return axios.get("/api/v3/account/phone/exist", { phone });
}

// 获取手机验证码
export async function getPhoneVcode(phone) {
  return axios.post(`/api/v3/account/phone/sendSms?phone=${phone}`);
}

// 修改密码
export function updatePwd(params) {
  return axios.post("/api/v3/account/update/pwd", params);
}

// 抖音注册
export function openIdLogin(pf, data) {
  if (!data.clientKey) data.clientKey = jingyu_clientkey;
  return axios.post(`/api/v3/account/${pf}/openid/login`, data);
}

export function openIdAccountLogin(appid, data) {
  return axios.post(`/api/v4/account/${appid}/login`, data);
}

// 添加抖音账号
export function addAuthAccount(data) {
  if (!data.clientKey) data.clientKey = jingyu_clientkey;
  return axios.post(`/api/v4/shop/web/auth`, data);
}

export function reAuthAccount(data) {
  if (!data.clientKey) data.clientKey = jingyu_clientkey;
  return axios.post(`/api/v4/shop/web/reauth`, data);
}

export function addAuthAccount2(data) {
  if (!data.clientKey) data.clientKey = jingyu_clientkey;
  return axios.post(`/api/v4/shop/web/auth2`, data);
}

// delete 抖音账号
export function delDouyinAccount(id) {
  return axios.post(`/api/v4/shop/douyin/${id}/delete`);
}

// 获取用户信息
export function getUserinfo(accessToken) {
  return axios.getLogin(`/api/v3/account/get_user_info`, accessToken);
}

// 获取用户信息
export function getShopUserinfo(accessKey) {
  return axios.get(`/api/v4/shop/getShopLoginByKey`, accessKey);
}

export function updateAccount(unionid, openid, clientKey) {
  return axios.post(`/api/v4/account/update/account`, {
    unionid,
    openid,
    clientKey,
  });
}

//
export async function bindPhone(phone, code) {
  return axios.post(`/api/v3/account/bind/phone?phone=${phone}&vcode=${code}`);
}
