import axios from "@/utils/axios";

// 查询图片分类
export function getPicCategoryList(data) {
  return axios.get(`/api/v4/source/category/findList`, data);
}

export function getTemplateCategorys() {
  return axios.get(`/api/v4/source/category/findTemplateCategorys`);
}

export function createPicCategory(data) {
  return axios.post(`/api/v4/source/category/create`, data);
}

export function updatePicCategory(categoryId, data) {
  return axios.post(`/api/v4/source/category/${categoryId}/update`, data);
}

export function deletePicCategory(categoryId) {
  return axios.post(`/api/v4/source/category/${categoryId}/delete`);
}

// 查询图片素材
export function getCategoryPicList(data) {
  return axios.get(`/api/v4/source/img/findList`, data);
}

export function addCategoryPic(data) {
  return axios.filePost(`/api/v4/source/img/upload`, data);
}

export function deletePic(sourceId) {
  return axios.post(`/api/v4/source/img/${sourceId}/delete`);
}

export function updatePic(sourceId, data) {
  return axios.post(`/api/v4/source/img/${sourceId}/update`, data);
}

export function getVideoDetail(data) {
  return axios.get(`/api/v4/source/tiny/getVideoDetail`, data);
}

// 手动同步店铺的视频列表
export function syncVideos() {
  return axios.post(`/api/v4/source/tiny/syncVideos`);
}
//同步视频状态
export function syncWrapper() {
  return axios.post(`/api/v4/source/tiny/syncWrapper`);
}
// 获取短视频列表
export function getVideoList({
  shopId,
  accountId,
  status,
  videoStatus,
  startTimeStr,
  endTimeStr,
  keyword,
  pageNum = 1,
  pageSize = 10,
}) {
  const params = {
    pageNum,
    pageSize,
  };
  if (shopId) params.shopId = shopId;
  if (accountId) params.accountId = accountId;
  if (status) params.status = status;
  if (videoStatus) params.videoStatus = videoStatus;
  if (keyword) params.keyword = keyword;
  if (startTimeStr) params.startTimeStr = startTimeStr;
  if (endTimeStr) params.endTimeStr = endTimeStr;
  return axios.get(`/api/v4/source/tiny/getVideoList`, params);
}

// 获取短视频观看iframecode
export function getVideoIframeCode(params) {
  return axios.get(`/api/v4/source/tiny/iframeCode`, params);
}

export function getVideoIframeCodeByItemId(params) {
  return axios.get(`/api/v4/source/tiny/iframeCodeByItemId`, params);
}

// 直接上传的接口
export function straightUpload(formData) {
  return axios.filePost(`/api/v4/source/tiny/upload`, formData);
}

// 开始分片
export function startPartUpload({ accountId }) {
  return axios.post(`/api/v4/source/tiny/startPartUpload`, { accountId });
}

// 分片上传
export function partUpload(formData) {
  return axios.filePost(`/api/v4/source/tiny/partUpload`, formData);
}

// 结束分片上传
export function completePartUpload({ accountId, uploadId }) {
  return axios.post(`/api/v4/source/tiny/completePartUpload`, {
    accountId,
    uploadId,
  });
}

// 获取话题列表
export function getHotTopicList() {
  return axios.post(`/api/v4/data/queryHotTopic`);
}

// 视频文件直接上传文件至oss，并且返回tinySource
export function uploadTiny2(file) {
  let param = new FormData();
  param.append("file", file);
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.filePost("/api/v4/source/tiny/upload2", param, config);
}

export function uploadTiny3(data) {
  return axios.post(`/api/v4/source/tiny/upload3`, data);
}

// 创建视频
export function createVideo(data) {
  return axios.post(`/api/v4/source/tiny/createVideo`, data);
}

// 获取评论列表
export function getCommentList({ tinyId, targetId, pageNum, pageSize }) {
  const params = {
    pageNum,
    pageSize,
  };
  if (targetId) {
    params.targetId = targetId;
  }
  if (tinyId) {
    params.tinyId = tinyId;
  }
  return axios.get(`/api/v4/source/comment/list`, params);
}

// 同步评论
export function syncComment({ tinyId, commentId }) {
  const params = {
    tinyId,
  };
  if (commentId) params.commentId = commentId;
  return axios.post(`/api/v4/source/comment/async`, params);
}

// 置顶评论
export function topComment({ commentId, top }) {
  const params = {
    commentId,
    top,
  };
  return axios.post("/api/v4/source/comment/top", params);
}

// 回复评论
export function replyComment({ commentId, content }) {
  const params = {
    commentId,
    content,
  };
  return axios.post(`/api/v4/source/comment/reply`, params);
}

export function getTalkList(data) {
  return axios.get(`/api/v4/source/talk/findList`, data);
}

export function getTalkDetail(talkId, data) {
  return axios.get(`/api/v4/source/talk/${talkId}/show`, data);
}

export function createTalk(data) {
  return axios.post(`/api/v4/source/talk/create`, data);
}

export function updateTalk(id, data) {
  return axios.post(`/api/v4/source/talk/${id}/update`, data);
}

export function deleteTalk(id) {
  return axios.post(`/api/v4/source/talk/${id}/delete`);
}

export function getSOPList(data) {
  return axios.get(`/api/v4/source/sop/findList`, data);
}

export function getSOPDetail(sopId, data) {
  return axios.get(`/api/v4/source/sop/${sopId}/show`, data);
}

export function createSOP(data) {
  return axios.post(`/api/v4/source/sop/create`, data);
}

export function updateSOP(id, data) {
  return axios.post(`/api/v4/source/sop/${id}/update`, data);
}

export function deleteSOP(id) {
  return axios.post(`/api/v4/source/sop/${id}/delete`);
}

export function getSourceVideoList(data) {
  return axios.get(`/api/v4/source/video/findList`, data);
}

export function createSourceVideo(data) {
  return axios.filePost(`/api/v4/source/video/upload`, data);
}

export function updateSourceVideo(id, data) {
  return axios.post(`/api/v4/source/video/${id}/update`, data);
}

export function deleteSourceVideo(id) {
  return axios.post(`/api/v4/source/video/${id}/delete`);
}

export function getVideoechart(data) {
  return axios.get(`/api/v4/source/tiny/getVideoechart`, data);
}

export function getPayCode(data) {
  return axios.post(`/api/v4/pay/getPayCode`, data);
}

export function getOrder(data) {
  return axios.get(`/api/v4/pay/getOrder`, data);
}

export function getGoods(data) {
  return axios.get(`/api/v4/order/goods/getList`, data);
}
