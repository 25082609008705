<template>
  <div>
    <el-dialog
      :visible.sync="showDialog"
      custom-class="page-fee-dialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <img
        @click="closeDialog"
        class="closeFeeDialog"
        src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_close_dialog.png"
      />
      <div class="fee-dialog-header">
        <div
          class="fee-tab"
          v-for="(vip, i) in vips"
          :key="i"
          :class="{ active: index === i }"
          @click="changeTab(i)"
        >
          <img
            :src="
              'https://image.renrenjiang.cn/domain_tools/icons/icon_fee_vip' +
              (i + 1) +
              '.png'
            "
            alt=""
          />
          {{ vipObj[vip] }}
        </div>
      </div>
      <div class="fee-dialog-content">
        <div class="fee-dialog-content-left">
          <div class="fee-left-tip">
            您当前为<span v-if="shops && shops.shop">{{
              vipObj[shops.shop.shop_version]
            }}</span
            >, 升级版本后可查看更多完整数据和功能
            <el-button type="text" size="small" @click="pageToFee"
              >查看详情</el-button
            >
          </div>
          <div class="fee-left-price-list" v-if="goodsList">
            <div
              v-for="(item, i) in goodsList[vips[index]]"
              :key="item.id"
              class="fee-left-price-li"
              @click="changePrice(i)"
            >
              <!--        :class="{ 'fee-left-price-li-active': priceType === i }" -->
              <h3>{{ ["标准版", "企业版", "矩阵版"][index] }}套餐</h3>
              <div class="fee-li-price">
                ¥<span>{{ item.price / 100 }}</span
                >/年
              </div>
              <!-- <p>总价 ¥{{ item.price / 100 }}</p>
              <p v-if="item.gift_days > 0" class="price-li-tag">
                多送{{ item.gift_days }}天
              </p> -->
            </div>
          </div>
          <!-- <div class="sale-price">818限时立减2000元, 免费赠送</div> -->
          <!-- <div class="fee-form-line">
          <div class="fee-form-title">支付方式</div>
          <div
            class="fee-form-type"
            :class="{ 'fee-form-type-active': typeIndex === 0 }"
            @click="typeIndex = 0"
          >
            <img
              src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_wxpay.png"
              alt=""
            />
            微信
          </div>
          <div
            class="fee-form-type"
            :class="{ 'fee-form-type-active': typeIndex === 1 }"
            @click="typeIndex = 1"
          >
            <img
              src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_alipay.png"
              alt=""
            />
            支付宝
          </div>
        </div> -->
          <div class="fee-qrcode-box">
            <el-image
              v-loading="loading"
              class="fee-qrcode-img"
              :src="qrcode"
            ></el-image>
            <div class="fee-qrcode-box-right">
              <div class="fee-form-line">
                <!-- <div class="fee-form-title"></div> -->
                <div
                  class="fee-form-type"
                  :class="{ 'fee-form-type-active': typeIndex === 0 }"
                  @click="changePay(0)"
                >
                  <img
                    src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_wxpay.png"
                    alt=""
                  />
                  微信
                </div>
                <div
                  class="fee-form-type"
                  :class="{ 'fee-form-type-active': typeIndex === 1 }"
                  @click="changePay(1)"
                >
                  <img
                    src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_alipay.png"
                    alt=""
                  />
                  支付宝
                </div>
              </div>
              <div class="fee-form-line">
                <div class="fee-form-title">支付金额：</div>
                <div class="pay-price" v-if="goodsList">
                  ¥<span>{{
                    goodsList[vips[index]][priceType].price / 100
                  }}</span>
                </div>
              </div>
              <div class="fee-form-line">
                支付即视为您同意《软件应用服务协议》
              </div>
            </div>
          </div>

          <!-- <div class="fee-form-line">
            <div class="fee-form-title">支付金额</div>
            <div class="pay-price" v-if="goodsList">
              ¥<span>{{ goodsList[vips[index]][priceType].price / 100 }}</span>
            </div>
          </div>
          <div class="fee-qrcode-box">
            <div class="fee-qrcode">
              
            </div>
          </div> -->

          <!-- <el-button type="primary" size="small" style="width: 320px"
          >立即开通</el-button
        > -->
        </div>
        <div class="fee-dialog-content-right" v-if="goodsList">
          <h2>{{ vipObj[vips[index]] }}</h2>
          <!-- <div v-for="(text, i) in vipContent[index + 1]" :key="i">
            {{ text }}
          </div> -->
          <div
            v-for="(text, i) in goodsList[vips[index]][0].authList.slice(0, 7)"
            :key="i"
          >
            {{ text.num > 0 ? text.num + "个" : "" }}{{ text.description }}
          </div>
        </div>
      </div>
      <div class="fee-dialog-footer">
        <div @click="showWXDialog = true">
          <!-- <img
          src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_money.png"
        /> -->
          <div></div>
          充值未生效
        </div>
        <div @click="showWXDialog = true">
          <div></div>
          <!-- <img
          src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_order.png"
        /> -->
          电子发票
        </div>
        <div @click="showWXDialog = true">
          <div></div>
          <!-- <img
          src="https://image.renrenjiang.cn/domain_tools/icons/icon_fee_serve.png"
        /> -->
          联系客服
        </div>
        <div @click="pageToServe">
          开通前阅读<el-button type="text">《软件应用服务协议》</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showStatusDialog"
      custom-class="fee-status-dialog"
      :close-on-click-modal="false"
      width="378px"
    >
      <template v-if="status === 'SUCCESS'">
        <el-image
          class="fee-status-dialog-img"
          src="https://image.renrenjiang.cn/domain_tools/icons/fee-status-dialog-success.png"
        ></el-image>
        <div class="fee-status-dialog-title">支付成功</div>
        <div class="fee-status-dialog-desc">
          购买商品：{{ buyInfo.name || "鲸鱼私域会员" }}
        </div>
        <div class="fee-status-dialog-desc">
          有效期：{{ buyInfo.expire_time || "一年" }}
        </div>
        <div class="fee-status-dialog-btn" @click="changeStatus">我知道了</div>
      </template>
      <template v-else>
        <el-image
          class="fee-status-dialog-img"
          src="https://image.renrenjiang.cn/domain_tools/icons/fee-status-dialog-error.png"
        ></el-image>
        <div class="fee-status-dialog-title">支付异常，请重新支付</div>
        <div class="fee-status-dialog-btn">重新支付</div>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="showWXDialog" width="300px" :show-close="false">
      <div class="dialog-head">
        <div class="dialog-head-title">专属顾问</div>
        <div class="dialog-head-close" @click="showWXDialog = false"></div>
      </div>
      <div class="dialog-body padding">
        <div class="layout-question-code">
          <el-image
            src="https://image.renrenjiang.cn/domain_tools/icons/question_code.jpg"
          ></el-image>
          <div>扫码对接专属顾问</div>
          <div>一对一产品运营指导</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { getPayCode, getOrder, getGoods } from "@/api/source";
import { findUserShops } from "@/api/account";
export default {
  data() {
    return {
      vips: ["standard", "special", "matrix"],
      vipObj: {
        trial: "试用版",
        standard: "标准版",
        special: "企业版",
        matrix: "矩阵版",
      },
      showDialog: false,
      index: 0,
      vipContent: {
        1: [
          "1个抖音账号管理权限",
          "5个群管理权限",
          "10个落地页管理权限",
          "手动添加客户标签",
          "40G素材储存空间",
          "1000条外链短信",
        ],
        2: [
          "3个抖音账号管理权限",
          "20个群管理权限",
          "50个落地页管理权限",
          "手动添加客户标签",
          "智能标签",
          "100G素材储存空间",
          "5000条外链短信",
        ],
        3: [
          "7个抖音账号管理权限",
          "50个群管理权限",
          "100个落地页管理权限",
          "手动添加客户标签",
          "智能标签",
          "500G素材储存空间",
          "账号分析",
          "30000条外链短信",
        ],
        4: [
          "15个抖音账号管理权限",
          "100个群管理权限",
          "不限制落地页管理权限",
          "手动添加客户标签",
          "智能标签",
          "1000G素材储存空间",
          "账号分析",
          "100000条外链短信",
        ],
      },
      priceType: 0,
      typeIndex: 0,
      referrer: "",
      coupon: 1,
      showStatusDialog: false,
      status: "SUCCESS",
      qrcode: "",
      outTradeNo: "",
      timer: null,
      goodsList: null,
      shops: {},
      buyInfo: {},
      showWXDialog: false,
      loading: true,
      html: "",
    };
  },
  async created() {
    this.getGoodsList();

    const _skit = this.$rrj.storage.get("_skit");
    let res = await findUserShops();
    this.shops = res.data.find((val) => val.shop.id === _skit.id);
    console.log(this.shops.shop, 11111, _skit.id);
  },
  async mounted() {},
  methods: {
    changePay(i) {
      if (this.typeIndex === i) return;
      this.typeIndex = i;
      this.getCode();
    },
    async getGoodsList() {
      const res = await getGoods();
      console.log(res);
      if (res && res.code === 200) {
        let goodsList = {};

        res.data.forEach((item) => {
          if (!goodsList[item.type]) {
            goodsList[item.type] = [item];
          } else {
            goodsList[item.type].push(item);
          }
        });

        this.goodsList = goodsList;
        console.log(this.goodsList, "goods list");
      }
    },
    async start(val) {
      this.index = val;
      this.showDialog = true;

      console.log(
        this.goodsList[this.vips[this.index]][this.priceType].id,
        "productId"
      );
      this.getCode();
    },
    async getCode() {
      this.loading = true;
      if (this.typeIndex === 0) {
        this.weixinPay();
      } else {
        // 支付宝
        this.aliPay();
      }
    },
    async weixinPay() {
      const res = await getPayCode({
        productId: this.goodsList[this.vips[this.index]][this.priceType].id,
        type: 0,
      });
      console.log(res);
      if (res && res.code === 200) {
        this.outTradeNo = res.data.outTradeNo;

        const generateQR = async (text) => {
          try {
            this.qrcode = await QRCode.toDataURL(text);
            this.loading = false;
          } catch (err) {
            console.error(err);
          }
        };

        await generateQR(res.data.code);

        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setInterval(async () => {
          const res = await getOrder({ outTradeNo: this.outTradeNo });
          if (res && res.code === 200) {
            if (res.data && res.data.status && res.data.status > 0) {
              clearInterval(this.timer);
              this.showStatusDialog = true;

              if (res.data.status === 1) {
                // 支付成功
                this.status = "SUCCESS";
                this.buyInfo = res.data;
              } else if (res.data.status === 2) {
                this.status = "Fail";
              } else if (res.data.status === 3) {
                this.status = "Fail";
              }
            }
          }
        }, 2000);
      }
    },
    async aliPay() {
      const res = await getPayCode({
        productId: this.goodsList[this.vips[this.index]][this.priceType].id,
        type: 1,
      });
      console.log(res);
      if (res && res.code === 200) {
        this.outTradeNo = res.data.outTradeNo;
        this.html = res.data.code;

        const div = document.createElement("div");

        div.innerHTML = res.data.code;

        document.body.appendChild(div);
        document.forms[0].setAttribute("target", "_blank"); // 新开窗口跳转
        document.forms[0].submit();
        div.remove();

        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setInterval(async () => {
          const res = await getOrder({ outTradeNo: this.outTradeNo });
          if (res && res.code === 200) {
            if (res.data && res.data.status && res.data.status > 0) {
              clearInterval(this.timer);
              this.showStatusDialog = true;

              if (res.data.status === 1) {
                // 支付成功
                this.status = "SUCCESS";
                this.buyInfo = res.data;
              } else if (res.data.status === 2) {
                this.status = "Fail";
              } else if (res.data.status === 3) {
                this.status = "Fail";
              }
            }
          }
        }, 2000);
      }
      this.loading = true;
    },
    changeTab(i) {
      this.index = i;
      this.priceType = 0;
      this.getCode();
    },
    changePrice(i) {
      this.priceType = i;
      this.getCode();
    },
    closeDialog() {
      this.showDialog = false;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    changeStatus() {
      this.closeDialog();
      this.showStatusDialog = false;
      this.$emit("changeStatus");
    },
    pageToFee() {
      let routeData = this.$router.resolve({
        path: "/fee",
      });
      window.open(routeData.href, "_blank");
    },
    pageToServe() {
      window.open(
        "https://cdn-hz.renrenjiang.cn/html/jingpinke/protocol.html",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
.page-fee-dialog {
  position: relative;
  width: 602px;
  height: 415px;
  border-radius: 20px;
  opacity: 1;

  background: #ffffff;

  .layout-question-code {
    text-align: center;
    padding-bottom: 20px;
    .el-image {
      width: 144px;
      height: 144px;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    div {
      font-size: 12px;
      line-height: 20px;
      color: #686767;
    }
  }

  .fee-dialog-content {
    .fee-qrcode-box {
      display: flex;
      padding-top: 25px;
      .fee-qrcode-img {
        box-sizing: border-box;
        width: 105px;
        height: 105px;
        margin-right: 15px;
        border: 1px solid #eaeaea;
        // .fee-qrcode-img {
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .fee-qrcode-box-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .fee-qrcode-box-right .fee-form-line {
        margin-bottom: 0;

        font-size: 12px;
        color: #acacac;
      }
    }
  }

  .input-item {
    width: 240px;
  }

  .closeFeeDialog {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  .fee-dialog-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 78px;
    padding-left: 50px;
    padding-right: 50px;

    background: linear-gradient(90deg, #1a1511 0%, #4a3929 100%);

    .fee-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 53px;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 18px 18px 0px 0px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 0;

      img {
        width: 21.5px;
        height: 19px;
        margin-right: 6px;
      }
    }

    .active {
      color: #090909;
      background-color: #fff;
    }
  }

  .fee-dialog-content {
    display: flex;
    height: 282px;

    .fee-dialog-content-left {
      flex: 1;
      padding-left: 50px;
      padding-top: 15px;
      .fee-left-tip {
        padding-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        color: #acacac;
        > span {
          color: #686767;
        }
      }

      .fee-left-price-list {
        display: flex;
        // width: 155px;
        // height: 103px;
        border-radius: 10px;
        background: #ffffff;
        box-sizing: border-box;

        .fee-left-price-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // position: relative;
          // text-align: center;
          // width: 155px;
          // height: 103px;
          // margin-right: 15px;
          // border: 1px solid #f0f6ff;
          // border-radius: 10px;
          box-sizing: border-box;
          width: 347px;
          height: 78px;
          border-radius: 6px;
          background: #f7faff;
          box-sizing: border-box;
          border: 1px solid #0366ff;
          padding: 0 15px;

          h3,
          p {
            margin: 0;
          }

          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #090909;
          }

          p {
            font-size: 12px;
            // text-align: center;
            line-height: 20px;
            color: #acacac;
          }

          .fee-li-price {
            color: #ff812d;
            span {
              font-size: 36px;
              font-weight: 600;
              line-height: 44px;
            }
          }

          .price-li-tag {
            position: absolute;
            top: 0;
            left: 0;
            width: 61px;
            height: 20px;
            border-radius: 10px 0 10px 0;
            font-size: 12px;
            color: #ffffff;
            background: #ff812d;
          }
        }

        .fee-left-price-li-active {
          background: url(https://image.renrenjiang.cn/domain_tools/icons/fee_vip_type.png)
            center/cover no-repeat;
          border: 1px solid transparent;
        }
      }

      .sale-price {
        box-sizing: border-box;
        width: 495px;
        height: 30px;
        margin-top: 20px;
        margin-bottom: 25px;
        padding: 5px 25px 5px 10px;
        border-radius: 8px;
        background: #fff5ee;

        font-size: 12px;
        line-height: 20px;

        color: #ff812d;
      }

      .fee-form-line {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .fee-form-title {
          width: 68px;
          font-size: 12px;
          color: #686767;
        }

        .active-title {
          color: #ff812d;
        }

        .fee-form-type {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 110px;
          height: 30px;
          margin-right: 15px;

          border-radius: 6px;

          background: #ffffff;

          box-sizing: border-box;
          border: 1px solid #f0f6ff;
          cursor: pointer;

          img {
            width: 20px;
            margin-right: 5px;
          }
        }

        .fee-form-type-active {
          background: url(https://image.renrenjiang.cn/domain_tools/icons/fee_pay_type.png)
            center/cover no-repeat;
          border: 1px solid transparent;
        }

        .pay-price {
          font-size: 12px;
          line-height: 20px;
          color: #ff812d;

          span {
            font-size: 20px;
            font-weight: 600;
            line-height: 44px;
          }
        }
      }
    }
    .fee-dialog-content-right {
      width: 180px;
      height: 282px;

      background: #f7fdff
        url(https://image.renrenjiang.cn/domain_tools/icons/icon_fee_vip_bg.png?t=1)
        center/cover no-repeat;

      h2 {
        margin: 0 0 10px;
        padding-top: 12px;
        text-align: center;
        line-height: 44px;

        font-size: 24px;
        color: #090909;
      }

      div {
        position: relative;
        padding-left: 40px;
        padding-bottom: 10px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        color: #686767;
      }

      div::before {
        position: absolute;
        content: "";
        top: 8px;
        left: 26px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: #acacac;
      }
    }
  }

  .fee-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #f7f8fa;

    > div {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    > div div {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    div:nth-child(1) div {
      background: url(https://image.renrenjiang.cn/domain_tools/icons/icon_fee_money.png)
        center/ cover no-repeat;
    }
    div:nth-child(2) div {
      background: url(https://image.renrenjiang.cn/domain_tools/icons/icon_fee_order.png)
        center/ 13.5px 15.5px no-repeat;
    }
    div:nth-child(3) div {
      background: url(https://image.renrenjiang.cn/domain_tools/icons/icon_fee_serve.png)
        center/ cover no-repeat;
    }
  }
}

.fee-status-dialog {
  text-align: center;
  &-img {
    width: 56px;
    height: 56px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  &-title {
    font-weight: 600;
    font-size: 18px;
    color: #090909;
    padding-bottom: 20px;
  }

  &-desc {
    font-size: 14px;
    line-height: 22px;
    color: #acacac;
    padding-bottom: 5px;
  }

  &-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 32px;
    margin-top: 45px;
    margin-bottom: 25px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #acacac;

    font-size: 14px;
    color: #686767;
    cursor: pointer;
  }
}
</style>
