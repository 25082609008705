<template>
  <div v-if="show" class="renewal-box">
    <div v-if="type === 1" class="renewal-content">
      <div class="renewal-content-title">您的权限已到期</div>
      <div class="renewal-content-desc">请续费体验更多功能</div>
      <div class="renewal-btn" @click="pageToFee">立即续费</div>
    </div>
    <div v-else class="renewal-content">
      <div class="renewal-content-title">您的权限不足</div>
      <div class="renewal-content-desc">请升级体验更多功能</div>
      <div class="renewal-btn" @click="pageToFee">立即升级</div>
    </div>
    <el-image
      @click="hide"
      class="renewal-close"
      src="https://image.renrenjiang.cn/domain_tools/icons/icon_renewal_close.png"
    ></el-image>
  </div>
</template>
<script>
import { formatDateTime } from "@/utils/index";
export default {
  props: {
    type: {
      type: Number, // 1 续费  2升级
      default: 1,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    pageToFee() {
      this.hide();

      this.$emit("pageToFee");
    },
    hide() {
      this.show = false;
      this.$rrj.storage.set("_renewal", formatDateTime(new Date("YYYY-MM-DD")));
    },
    async start() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.renewal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;

  text-align: center;

  .renewal-content {
    background: url(https://image.renrenjiang.cn/domain_tools/icons/icon_renewal_bg.png)
      center/cover no-repeat;
    width: 415px;
    height: 408px;
    .renewal-content-title {
      padding-top: 65px;
      padding-bottom: 15px;
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      color: #0366ff;
    }
    .renewal-content-desc {
      font-size: 20px;
      line-height: 28px;
      color: #686767;
    }
  }

  .renewal-btn {
    margin-top: 163px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 64px;
    border-radius: 147px;
    background: linear-gradient(180deg, #ff8235 3%, #ff2200 100%);
    box-shadow: inset 0px -4px 5px 0px rgba(200, 13, 0, 0.77),
      inset 0px 4px 10px 0px rgba(255, 238, 170, 0.65);

    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
  }

  .renewal-close {
    width: 30px;
    height: 30px;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>
