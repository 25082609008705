import axios from "@/utils/axios";

export function addLog(data) {
  return axios.post(`/api/v3/teacher_hybrid/add_log_new`, data);
}

export function getDouyinList(data) {
  return axios.get(`/api/v4/shop/douyin/findList`, data);
}

export function asyncGroups(data) {
  console.log("data", data);
  return axios.post(`/api/v4/channel/group/async`, data);
}

export function applyScopes(scopes) {
  return axios.post(`/api/v3/teacher_hybrid/douyin/xcx/req_scopes`, {
    scopes: scopes,
  });
}

export function applyReqScopes(data) {
  return axios.post(`/api/v4/shop/xcx/req_scopes`, data);
}

export function getGroupAuth(data) {
  return axios.get(`/api/v4/shop/xcx/find_auth`, data);
  // return axios.get(`/api/v3/teacher_hybrid/douyin/xcx/find_auth`, data);
}

export function findReqScopes(data) {
  return axios.get(`/api/v4/shop/xcx/find_req_scopes`, data);
}

export function reportScopes(data) {
  return axios.post(`/api/v4/shop/xcx/auth`, data);
}

export function getRoleList(data) {
  return axios.get(`/api/v4/shop/role/findPageList`, data);
}

export function getUserDetail(data) {
  return axios.get(`/api/v4/shop/user/findByRrjUser`, data);
}

export function getRoleShow(roleId, data) {
  return axios.post(`/api/v4/shop/role/${roleId}/show`, data);
}

export function createRole(data) {
  return axios.post(`/api/v4/shop/role/create`, data);
}

export function updateRole(roleId, data) {
  return axios.post(`/api/v4/shop/role/${roleId}/update`, data);
}

export function deleteRole(roleId, data) {
  return axios.post(`/api/v4/shop/role/${roleId}/delete`, data);
}

export function createUser(data) {
  return axios.post(`/api/v4/shop/user/create`, data);
}

export function updateUser(id, data) {
  return axios.post(`/api/v4/shop/user/${id}/update`, data);
}

export function deleteUser(id, data) {
  return axios.post(`/api/v4/shop/user/${id}/delete`, data);
}

export function setUserStatus(id, data) {
  return axios.post(`/api/v4/shop/user/${id}/setStatus`, data);
}

export function systemSwitch(data) {
  return axios.get(`/api/v3/system/switch`, data);
}
