import Vue from "vue";
import VueRouter from "vue-router";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["@/views/passport/login"], resolve),
  },
  {
    path: "/register",
    name: "register",
    component: (resolve) => require(["@/views/passport/register"], resolve),
  },
  {
    path: "/forget",
    name: "forget",
    component: (resolve) => require(["@/views/passport/forget"], resolve),
  },
  {
    path: "/shops",
    name: "selectShops",
    component: (resolve) => require(["@/views/passport/shops"], resolve),
  },
  {
    path: "/create",
    name: "createShop",
    component: (resolve) => require(["@/views/passport/create"], resolve),
  },
  {
    path: "/fee",
    name: "fee",
    component: (resolve) => require(["@/views/setting/fee"], resolve),
  },
  {
    path: "/",
    component: (resolve) => require(["@/views/passport/account"], resolve),
    children: [
      {
        path: "/account/setting",
        name: "setting",
        component: (resolve) => require(["@/views/passport/setting"], resolve),
      },
      {
        path: "/account/manage",
        name: "manage",
        component: (resolve) => require(["@/views/passport/manage"], resolve),
      },
    ],
  },
  {
    path: "/designer",
    name: "designer",
    component: (resolve) => require(["@/views/designer/index"], resolve),
  },
  {
    path: "/customer",
    name: "customer",
    component: (resolve) => require(["@/views/customer/index"], resolve),
  },
  {
    path: "/home",
    component: (resolve) => require(["@/views/layout/index"], resolve),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: (resolve) => require(["@/views/layout/dashboard"], resolve),
      },
      {
        path: "/auth",
        name: "auth",
        component: (resolve) => require(["@/views/layout/auth"], resolve),
      },
      {
        path: "/data/account",
        name: "accountData",
        component: (resolve) => require(["@/views/layout/analysis"], resolve),
      },
      {
        path: "/shop/pages",
        name: "shopPages",
        component: (resolve) => require(["@/views/shop/pages"], resolve),
      },
      {
        path: "/shop/nav",
        name: "shopNav",
        component: (resolve) => require(["@/views/shop/nav"], resolve),
      },
      {
        path: "/shop/nav/edit",
        name: "shopNavEdit",
        component: (resolve) => require(["@/views/shop/navEdit"], resolve),
      },
      {
        path: "/shop/live/url",
        name: "liveUrl",
        component: (resolve) => require(["@/views/live/index"], resolve),
      },
      {
        path: "/shop/live/url/viewAppointment",
        name: "viewAppointment",
        component: (resolve) =>
          require(["@/views/live/viewAppointment"], resolve),
      },
      {
        path: "/shop/live/url/viewPublic",
        name: "viewPublic",
        component: (resolve) => require(["@/views/live/viewPublic"], resolve),
      },
      {
        path: "/shop/live/url/editAppointment",
        name: "viewAppointment",
        component: (resolve) =>
          require(["@/views/live/editAppointment"], resolve),
      },
      {
        path: "/shop/live/url/editPublic",
        name: "viewPublic",
        component: (resolve) => require(["@/views/live/editPublic"], resolve),
      },
      {
        path: "/shop/coupon/url",
        name: "couponShopUrl",
        component: (resolve) => require(["@/views/shop/couponUrl"], resolve),
      },
      {
        path: "/shop/good/url",
        name: "goodShopUrl",
        component: (resolve) => require(["@/views/shop/goodUrl"], resolve),
      },
      {
        path: "/transform/channels",
        name: "transformChannels",
        component: (resolve) =>
          require(["@/views/transform/channels"], resolve),
      },
      {
        path: "/transform/channels/clues",
        name: "transformChannelsClues",
        component: (resolve) =>
          require(["@/views/transform/channelsClues"], resolve),
      },
      {
        path: "/transform/channels/edit",
        name: "transformChannelsEdit",
        component: (resolve) =>
          require(["@/views/transform/channelsEdit"], resolve),
      },
      {
        path: "/transform/groups",
        name: "transformGroups",
        component: (resolve) => require(["@/views/transform/groups"], resolve),
      },
      {
        path: "/transform/groups/list",
        name: "transformGroupsList",
        component: (resolve) =>
          require(["@/views/transform/groupsList"], resolve),
      },
      {
        path: "/transform/groups/edit",
        name: "transformGroupsEdit",
        component: (resolve) =>
          require(["@/views/transform/groupsEdit"], resolve),
      },
      {
        path: "/msg/list",
        name: "msgList",
        component: (resolve) => require(["@/views/msg/list"], resolve),
      },
      {
        path: "/msg/welcome",
        name: "msgWelcome",
        component: (resolve) => require(["@/views/msg/welcome"], resolve),
      },
      {
        path: "/msg/welcome/edit",
        name: "msgWelcomeEdit",
        component: (resolve) => require(["@/views/msg/welcomeEdit"], resolve),
      },
      {
        path: "/msg/autoreply",
        name: "msgAutoreply",
        component: (resolve) => require(["@/views/msg/autoreply"], resolve),
      },
      {
        path: "/msg/autoreply/edit",
        name: "msgAutoreplyEdit",
        component: (resolve) => require(["@/views/msg/autoreplyEdit"], resolve),
      },
      {
        path: "/msg/assist",
        name: "msgAssist",
        component: (resolve) => require(["@/views/msg/assist"], resolve),
      },
      {
        path: "/group/list",
        name: "groupList",
        component: (resolve) => require(["@/views/group/list"], resolve),
      },
      {
        path: "/group/edit",
        name: "groupEdit",
        component: (resolve) => require(["@/views/group/groupEdit"], resolve),
      },
      {
        path: "/group/memberList",
        name: "memberList",
        component: (resolve) => require(["@/views/group/memberList"], resolve),
      },
      {
        path: "/group/welcome",
        name: "groupWelcome",
        component: (resolve) => require(["@/views/group/welcome"], resolve),
      },
      {
        path: "/group/welcome/edit",
        name: "groupWelcomeEdit",
        component: (resolve) => require(["@/views/group/welcomeEdit"], resolve),
      },
      {
        path: "/group/autoreply",
        name: "groupAutoreply",
        component: (resolve) => require(["@/views/group/autoreply"], resolve),
      },
      {
        path: "/group/autoreply/edit",
        name: "groupAutoreplyEdit",
        component: (resolve) =>
          require(["@/views/group/autoreplyEdit"], resolve),
      },
      {
        path: "/group/assist",
        name: "groupAssist",
        component: (resolve) => require(["@/views/group/assist"], resolve),
      },
      {
        path: "/group/sop",
        name: "groupSop",
        component: (resolve) => require(["@/views/group/sop"], resolve),
      },
      {
        path: "/group/sop/edit",
        name: "groupSopEdit",
        component: (resolve) => require(["@/views/group/sopEdit"], resolve),
      },
      {
        path: "/group/gift",
        name: "groupGift",
        component: (resolve) => require(["@/views/group/groupGift"], resolve),
      },
      {
        path: "/group/gift/edit",
        name: "groupGiftEdit",
        component: (resolve) => require(["@/views/group/giftEdit"], resolve),
      },
      {
        path: "/group/gift/detail",
        name: "groupGiftDetail",
        component: (resolve) => require(["@/views/group/giftDetail"], resolve),
      },
      {
        path: "/group/link",
        name: "groupLink",
        component: (resolve) => require(["@/views/group/groupLink"], resolve),
      },
      {
        path: "/group/link/edit",
        name: "groupLinkEdit",
        component: (resolve) => require(["@/views/group/linkEdit"], resolve),
      },
      {
        path: "/group/link/detail",
        name: "groupLinkDetail",
        component: (resolve) => require(["@/views/group/linkDetail"], resolve),
      },
      {
        path: "/group/link/send/detail",
        name: "groupLinkSendDetail",
        component: (resolve) => require(["@/views/group/sendDetail"], resolve),
      },
      {
        path: "/market/invite",
        name: "marketInvite",
        component: (resolve) => require(["@/views/market/invite"], resolve),
      },
      {
        path: "/market/checkin",
        name: "marketCheckin",
        component: (resolve) => require(["@/views/market/checkin"], resolve),
      },
      {
        path: "/market/sensitive",
        name: "marketSensitive",
        component: (resolve) => require(["@/views/market/sensitive"], resolve),
      },
      {
        path: "/market/sensitive/edit",
        name: "marketSensitiveEdit",
        component: (resolve) =>
          require(["@/views/market/sensitiveEdit"], resolve),
      },
      {
        path: "/market/coupon",
        name: "marketCoupon",
        component: (resolve) => require(["@/views/market/coupon"], resolve),
      },
      {
        path: "/store/product",
        name: "storeProduct",
        component: (resolve) => require(["@/views/store/product"], resolve),
      },
      {
        path: "/store/product/edit",
        name: "storeProductEdit",
        component: (resolve) => require(["@/views/store/productEdit"], resolve),
      },
      {
        path: "/store/order",
        name: "storeOrder",
        component: (resolve) => require(["@/views/store/order"], resolve),
      },
      {
        path: "/store/logistics",
        name: "storeLogistics",
        component: (resolve) => require(["@/views/store/logistics"], resolve),
      },
      {
        path: "/store/aftersale",
        name: "storeAftersale",
        component: (resolve) => require(["@/views/store/aftersale"], resolve),
      },
      {
        path: "/sources/urllink",
        name: "sourceUrllink",
        component: (resolve) => require(["@/views/sources/urllink"], resolve),
      },
      {
        path: "/sources/sops",
        name: "sourceSops",
        component: (resolve) => require(["@/views/sources/sops"], resolve),
      },
      {
        path: "/sources/sops/edit",
        name: "sourceSopsEdit",
        component: (resolve) => require(["@/views/sources/sopsEdit"], resolve),
      },
      {
        path: "/sources/talks",
        name: "sourceTalks",
        component: (resolve) => require(["@/views/sources/talks"], resolve),
      },
      {
        path: "/sources/talks/edit",
        name: "sourceTalksEdit",
        component: (resolve) => require(["@/views/sources/talksEdit"], resolve),
      },
      {
        path: "/sources/pictures",
        name: "sourcePictures",
        component: (resolve) => require(["@/views/sources/pictures"], resolve),
      },
      {
        path: "/sources/video",
        name: "sourceVideo",
        component: (resolve) => require(["@/views/sources/video"], resolve),
      },
      {
        path: "/interact/tinys",
        name: "interactTinys",
        component: (resolve) => require(["@/views/interact/tinys"], resolve),
      },
      {
        path: "/interact/tiny/list",
        name: "interactTinyList",
        component: (resolve) => require(["@/views/interact/tinyList"], resolve),
      },
      {
        path: "/interact/createTiny",
        name: "createTiny",
        component: (resolve) =>
          require(["@/views/interact/createTiny"], resolve),
      },
      {
        path: "/interact/comment",
        name: "interactComment",
        component: (resolve) => require(["@/views/interact/comment"], resolve),
      },
      {
        path: "/interact/video/comment",
        name: "interactVideoComment",
        component: (resolve) =>
          require(["@/views/interact/videoComment"], resolve),
      },
      {
        path: "/interact/autoReply",
        name: "interactAutoReply",
        component: (resolve) =>
          require(["@/views/interact/autoReply"], resolve),
      },
      {
        path: "/interact/autoreply/edit",
        name: "interactAutoreplyEdit",
        component: (resolve) =>
          require(["@/views/interact/autoreplyEdit"], resolve),
      },
      {
        path: "/sources/tinys/edit",
        name: "sourceTinysEdit",
        component: (resolve) => require(["@/views/sources/tinysEdit"], resolve),
      },
      {
        path: "/member/clues",
        name: "memberClues",
        component: (resolve) => require(["@/views/member/clues"], resolve),
      },
      {
        path: "/member/clues/from",
        name: "showCluesFrom",
        component: (resolve) =>
          require(["@/views/member/showCluesFrom"], resolve),
      },
      {
        path: "/member/group",
        name: "memberGroup",
        component: (resolve) => require(["@/views/member/group"], resolve),
      },
      {
        path: "/member/tags",
        name: "memberTags",
        component: (resolve) => require(["@/views/member/tags"], resolve),
      },
      {
        path: "/member/tags/edit",
        name: "memberTagsEdit",
        component: (resolve) => require(["@/views/member/tagsEdit"], resolve),
      },
      {
        path: "/analysis/customer",
        name: "analysisCustomer",
        component: (resolve) => require(["@/views/analysis/customer"], resolve),
      },
      {
        path: "/analysis/channel",
        name: "analysisChannel",
        component: (resolve) => require(["@/views/analysis/channel"], resolve),
      },
      {
        path: "/analysis/message",
        name: "analysisMessage",
        component: (resolve) => require(["@/views/analysis/message"], resolve),
      },
      {
        path: "/analysis/group",
        name: "analysisGroup",
        component: (resolve) => require(["@/views/analysis/group"], resolve),
      },
      {
        path: "/analysis/content",
        name: "analysisContent",
        component: (resolve) => require(["@/views/analysis/content"], resolve),
      },
      {
        path: "/setting/info",
        name: "settingInfo",
        component: (resolve) => require(["@/views/setting/info"], resolve),
      },
      {
        path: "/setting/douyins",
        name: "settingDouyins",
        component: (resolve) => require(["@/views/setting/douyins"], resolve),
      },
      {
        path: "/setting/accounts",
        name: "settingAccounts",
        component: (resolve) => require(["@/views/setting/accounts"], resolve),
      },
      {
        path: "/setting/accounts/edit",
        name: "settingAccountEdit",
        component: (resolve) =>
          require(["@/views/setting/accountEdit"], resolve),
      },
      {
        path: "/setting/roles",
        name: "settingRoles",
        component: (resolve) => require(["@/views/setting/roles"], resolve),
      },
      {
        path: "/setting/roles/edit",
        name: "settingRolesEdit",
        component: (resolve) => require(["@/views/setting/rolesEdit"], resolve),
      },
      {
        path: "/tool/clue",
        name: "clueTool",
        component: (resolve) => require(["@/views/tool/clue"], resolve),
      },
      {
        path: "/tool/clue/edit",
        name: "clueToolEdit",
        component: (resolve) => require(["@/views/tool/formEdit"], resolve),
      },
      {
        path: "/tool/appoint",
        name: "appointTool",
        component: (resolve) => require(["@/views/tool/appoint"], resolve),
      },
      {
        path: "/tool/appoint/edit",
        name: "appointToolEdit",
        component: (resolve) => require(["@/views/tool/formEdit"], resolve),
      },
      {
        path: "/card/message",
        name: "cardMessage",
        component: (resolve) => require(["@/views/card/message"], resolve),
      },
      {
        path: "/card/group",
        name: "cardGroup",
        component: (resolve) => require(["@/views/card/group"], resolve),
      },
      {
        path: "/card/clue",
        name: "cardClue",
        component: (resolve) => require(["@/views/card/clue"], resolve),
      },
      {
        path: "/card/coupon",
        name: "cardCoupon",
        component: (resolve) => require(["@/views/card/coupon"], resolve),
      },
      {
        path: "/quick/message",
        name: "quickMessage",
        component: (resolve) => require(["@/views/quick/message"], resolve),
      },
      {
        path: "/quick/im",
        name: "quickIm",
        component: (resolve) => require(["@/views/quick/im"], resolve),
      },
      {
        path: "/quick/crowd",
        name: "quickCrowd",
        component: (resolve) => require(["@/views/quick/crowd"], resolve),
      },
      {
        path: "/quick/group",
        name: "quickGroup",
        component: (resolve) => require(["@/views/quick/group"], resolve),
      },
      {
        path: "/quick/clue",
        name: "quickClue",
        component: (resolve) => require(["@/views/quick/clue"], resolve),
      },
      {
        path: "/quick/coupon",
        name: "quickCoupon",
        component: (resolve) => require(["@/views/quick/coupon"], resolve),
      },
      {
        path: "/quick/list",
        name: "quickList",
        component: (resolve) => require(["@/views/quick/list"], resolve),
      },
      {
        path: "/quick/activity",
        name: "quickActivity",
        component: (resolve) => require(["@/views/quick/activity"], resolve),
      },
      {
        path: "/quick/createTiny",
        name: "quickCreateTiny",
        component: (resolve) => require(["@/views/quick/createTiny"], resolve),
      },
      {
        path: "/quick/live",
        name: "quickLive",
        component: (resolve) => require(["@/views/quick/live"], resolve),
      },
      {
        path: "/quick/form",
        name: "quickForm",
        component: (resolve) => require(["@/views/quick/form"], resolve),
      },
    ],
  },
  {
    path: "/help",
    component: (resolve) => require(["@/views/tool/help"], resolve),
    children: [
      {
        path: "/help/article/:id",
        name: "article",
        component: (resolve) => require(["@/views/tool/article"], resolve),
      },
    ],
  },
  {
    path: "/chat",
    name: "chat",
    component: (resolve) => require(["@/views/chat/index"], resolve),
    children: [
      // {
      //   path: "/chat/communication",
      //   name: "communication",
      //   component: (resolve) => require(["@/views/chat/chat"], resolve),
      // },
    ],
  },
  {
    path: "/website",
    name: "website",
    component: (resolve) => require(["@/views/website/index2"], resolve),
  },
  {
    path: "/about",
    name: "about",
    component: (resolve) => require(["@/views/website/about"], resolve),
  },
  {
    path: "/website2",
    name: "website2",
    component: (resolve) => require(["@/views/website/index"], resolve),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
