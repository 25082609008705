import axios from "@/utils/axios";

// 查询用户的店铺列表
export function findUserShops() {
  return axios.get("/api/v4/shop/findUserShops");
}

// 创建店铺
export function createShop(param) {
  return axios.post("/api/v4/shop/create", param);
}

// 修改店铺信息
export function updateShop(param) {
  return axios.post("/api/v4/shop/update", param);
}

// 查询店铺
export function findShop(param) {
  return axios.get("/api/v4/shop/showInfo", param);
}

// 上传店铺Logo
export function uploadLogo(data) {
  return axios.filePost(`/api/v4/shop/uploadLogo`, data);
}

// 查询用户的店铺列表
export function findUserList(param) {
  return axios.get("/api/v4/shop/user/findList", param);
}

// 查询用户详情
export function findUserInfo(id) {
  return axios.get(`/api/v4/shop/user/${id}/show`);
}

// 查询用户角色
export function findRoleOptions() {
  return axios.get(`/api/v4/shop/role/findList`);
}

// 查询角色权限
export function findRolesModules(param) {
  return axios.get(`/api/v4/shop/role/findModules`, param);
}

export function getShopTask(param) {
  return axios.get(`/api/v4/shop/getShopTask`, param);
}

export function shopBind(param) {
  return axios.post("/api/v4/shop/shopBind", param);
}
