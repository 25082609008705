import router from "@/router";
import { addLog } from "@/api/setting";
// import store from "./store";
import { $rrj } from "@/utils/rrjutils";
import { getUserinfo, getShopUserinfo } from "@/api/login";
const whiteList = ["/login", "/register", "/forget", "/website", "/about"]; // 不重定向白名单

function getPageParams() {
  let url = window.location.href;
  let param = {};
  url.replace(/([^?&]+)=([^?&/]+)/g, function (s, v, k) {
    param[v] = decodeURIComponent(k); //解析字符为中文
    return k + "=" + v;
  });
  return param;
}

router.beforeEach((to, from, next) => {
  // console.log(to, "start----");
  // console.log(from, "from----");
  // if (from.path == to.path) {
  //   return;
  // }

  // 授权跳转
  const { state, code, visted } = getPageParams();
  if (state !== "teacherwx" && visted != "true") {
    if (state === "10001") {
      console.log(state);
    } else if (state === "10002") {
      window.location.href = `https://www.jingyusiyu.com/#/login?code=${code}&state=${state}&visted=true`;
      return;
    } else if (state === "10003") {
      window.location.href = `https://www.jingyusiyu.com/#/dashboard?code=${code}&state=${state}&visted=true`;
      return;
    } else if (state === "10004") {
      window.location.href = `https://www.jingyusiyu.com/#/dashboard?code=${code}&state=${state}&visted=true`;
      return;
    } else if (state === "10007") {
      window.location.href = `https://www.jingyusiyu.com/#/auth?code=${code}&state=${state}&visted=true`;
      return;
    } else if (code && state) {
      console.log(code, state);
      return;
    }
  }

  const user = $rrj.storage.get("_ukit");
  const skit = $rrj.storage.get("_skit");
  if (user) {
    // 访问统计
    const data = {
      way: "doudian_assist",
      appName: "抖音私域助手",
      userId: (user && user.id) || 0,
      request_uri: to.fullPath,
      action: "pageVisit",
      teacherId: (skit && skit.id) || 0,
    };
    addLog(data);
  }

  const { access_token: accessToken, access_key: accessKey } = to.query;

  if (accessKey) {
    console.log(accessKey, "access");
    getShopUserinfo({ key: accessKey }).then((res) => {
      $rrj.storage.set("_ukit", res.data.user);
      $rrj.storage.set("_token", res.data.token);
      $rrj.storage.set("_skit", res.data.shop.shop);
      next({ path: "/dashboard" });
    });
  } else if (accessToken) {
    getUserinfo(accessToken).then((data) => {
      $rrj.storage.set("_ukit", data.user);
      $rrj.storage.set("_token", data.token);
      $rrj.storage.set("_refresh_token", data.refresh_token);
      next({ path: "/shops" });
    });
    // store
    //   .dispatch("TokenLogin", { accessToken })
    //   .then(() => {
    //     next({ path: "/dashboard" });
    //   })
    //   .catch(() => {
    //     next({ path: "/404", replace: true, query: { noGoBack: true } });
    //   });
  } else if ($rrj.storage.get("_token")) {
    if (to.path === "/login") {
      next({ name: "selectShops" });
    } else {
      if (to.path !== "/") {
        next();
      } else {
        next({ name: "selectShops" });
      }

      // console.log(store.getters.permission_routers, "router");
      // // 只有默认的路由
      // if (store.getters.addRouters.length === 0) {
      //   // 获取动态路由
      //   store
      //     .dispatch("GetAsyncRoutes")
      //     .then((bool) => {
      //       // console.log(bool);
      //       // console.log(store.getters.addRouters, "store.getters.addRouters");
      //       if (bool) {
      //         router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
      //         next({ ...to, replace: true });
      //       } else {
      //         next({ path: "/404", replace: true, query: { noGoBack: true } });
      //       }
      //     })
      //     .catch(() => {
      //       store.dispatch("LogOut").then(() => {
      //         next("/login");
      //       });
      //     });
      // } else {
      //   if (to.path !== "/") {
      //     next();
      //   } else {
      //     next("/website");
      //   }
      // }
    }
  }
  // else if (getRefreshToken()) {
  //   store
  //     .dispatch("RefreshToken")
  //     .then(() => {
  //       if (to.path === "/login") {
  //         next({ path: "/dashboard" });
  //       } else {
  //         next({ ...to, replace: true });
  //       }
  //     })
  //     .catch((err) => {
  //       store.dispatch("LogOut").then(() => {
  //         Message.error(err || "Verification failed, please login again");
  //         next({ path: "/" });
  //       });
  //     });
  // }
  else {
    if (to.path === "/") {
      next("/website");
    } else if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
      // NProgress.done();
    }
  }
});
