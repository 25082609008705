export const jingyu_clientkey = "awvo44ywnkz0vyxe";
export const rrj_clientkey = "awyu19tt8gwsp2gg";

export const jingyu_appid = "tt02ae74139d923b2401";
export const rrj_appid = "tt59532feec66ba0e701";

export const card_template = {
  tt02ae74139d923b2401: {
    card_template_id:
      "@9VwS2afGRd4+NG3xdsElRc771GboBsO5AK5KkjGdFGqGL1cXNUft3XG45L2WYb51",
    appid: "tt02ae74139d923b2401",
  },
  tt59532feec66ba0e701: {
    card_template_id:
      "@9VwS2afGRd4+NG3xdsElRc771mfsBsO5AK5KkjGdFGo5xLhvT22f0PdLu38bRezM",
    appid: "tt59532feec66ba0e701",
  },
};
